import {
  SET_EXPORT_SETTINGS,
  SET_USER_SETTINGS,
  SET_APP_SETTINGS,
  SET_DARK_MODE,
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  exportSettings: {
    saveSettings: true,
    savedType: 'word',
    scrivener: {
      general: {},
      outline: {
        export: true,
        columnOrRow: 'column',
        sceneCards: true,
        plotlineInTitle: true,
        attachments: true,
        description: true,
        descriptionHeading: true,
        where: 'synopsis',
        customAttributes: true,
        templates: true,
        filter: null,
      },
      characters: {
        export: true,
        heading: true,
        description: true,
        descriptionHeading: true,
        attachments: true,
        notesHeading: true,
        notes: true,
        customAttributes: true,
        templates: true,
        tags: true,
        categoryHeading: true,
        category: true,
      },
      places: {
        export: true,
        heading: true,
        description: true,
        descriptionHeading: true,
        attachments: true,
        notesHeading: true,
        notes: true,
        customAttributes: true,
        tags: true,
      },
      notes: {
        export: true,
        heading: true,
        attachments: true,
        content: true,
      },
    },
    word: {
      general: {
        titlePage: true,
      },
      outline: {
        export: true,
        columnOrRow: 'column',
        heading: true,
        sceneCards: true,
        plotlineInTitle: true,
        attachments: true,
        description: true,
        customAttributes: true,
        templates: true,
        filter: null,
      },
      characters: {
        export: true,
        heading: true,
        images: true,
        descriptionHeading: true,
        description: true,
        attachments: true,
        notesHeading: true,
        notes: true,
        customAttributes: true,
        templates: true,
        categoryHeading: true,
        category: true,
      },
      places: {
        export: true,
        heading: true,
        images: true,
        descriptionHeading: true,
        description: true,
        attachments: true,
        notesHeading: true,
        notes: true,
        customAttributes: true,
      },
      notes: {
        export: true,
        heading: true,
        images: true,
        attachments: true,
        content: true,
      },
    },
  },
  appSettings: {
    showTheTour: false,
    backup: true,
    allowPrerelease: false,
    forceDevTools: false,
    trialMode: false,
    canGetUpdates: true,
    isInGracePeriod: false,
    gracePeriodEnd: 0,
    canEdit: true,
    canExport: true,
    user: {
      autoDownloadUpdate: true,
      autoSave: true,
      backupDays: null,
      backupLocation: 'default',
      dark: 'system',
      numberOfBackups: null,
      openDashboardFirst: true,
      backupType: 'never-delete',
      localBackups: false,
      email: 'test@test.com',
      enableOfflineMode: false,
      useSpellcheck: true,
      useCardColorAsOutline: true,
      font: 'Forum',
      fontSize: 20,
      fonts: {
        rce: {
          defaultFont: 'Forum',
          defaultFontSize: '16pt',
          defaultDarkModeFontColor: '#CCCCCC',
          defaultFontColor: '#102A42', //$gray-0
          titleFont: 'Forum',
          titleFontSize: '28pt',
          titleFontWeight: 400,
          titleDarkModeFontColor: '#CCCCCC',
          titleFontColor: '#102A42',
          subtitleFont: 'Forum',
          subtitleFontSize: '24pt',
          subtitleFontWeight: 400,
          subtitleDarkModeFontColor: '#CCCCCC',
          subtitleFontColor: '#102A42',
        },
        global: {
          headingFont: 'IBM Plex Serif',
          bodyFont: 'Forum',
        },
        timeline: {
          headings: {
            font: 'IBM Plex Serif',
            fontSize: '24px',
          },
          plotlines: {
            font: 'IBM Plex Serif',
            fontSize: '20px',
          },
          sceneCardTitles: {
            font: 'Forum',
            fontSize: '16px',
          },
        },
      },
    },
  },
  userSettings: {
    // TODO: these seem to be the same as license data based on the
    // path of the USER store(!?)
  },
}

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_EXPORT_SETTINGS: {
      return {
        ...state,
        exportSettings: action.exportSettings,
      }
    }
    case SET_USER_SETTINGS: {
      return {
        ...state,
        userSettings: action.userSettings,
      }
    }
    case SET_APP_SETTINGS: {
      return {
        ...state,
        appSettings: action.appSettings,
      }
    }
    case SET_DARK_MODE: {
      return {
        ...state,
        appSettings: {
          ...state.appSettings,
          user: {
            ...state.appSettings.user,
            dark: action.value,
          },
        },
      }
    }
    default: {
      return state
    }
  }
}

export default settingsReducer
