import { selectors } from 'wired-up-pltr'

import { store } from './redux'
import { appVersion } from './version'
import createErrorReporter from './error-reporter'
import { logger } from './logger'

const NODE_ENV = process.env.NEXT_PUBLIC_NODE_ENV === 'development' ? 'development' : 'production'

export const ERROR_REPORTER_ACCESS_TOKEN =
  process.env.NEXT_PUBLIC_ROLLBAR_ACCESS_TOKEN || 'PHONY_ACCESS_TOKEN'
let generalErrorReporterInitialised = false
let generalErrorReporterInstance = {
  error: (...args) => {
    console.error('Attempted to use general error reporter but it is not initialised', ...args)
  },
}
// Note: there used to be a need to produce a promise from here.
// That's why it still produces a promise today.
export const getErrorReporterInstance = () => {
  if (generalErrorReporterInitialised) {
    return Promise.resolve(generalErrorReporterInstance)
  } else {
    const os = 'web'
    const version = appVersion()
    const state = store.getState()
    const userId = selectors.userIdSelector(state)
    const userEmail = selectors.emailAddressSelector(state)
    const fileURL = selectors.fileURLSelector(state)
    generalErrorReporterInstance = createErrorReporter(
      ERROR_REPORTER_ACCESS_TOKEN,
      version,
      NODE_ENV,
      logger,
      'general_error_reporter',
      os,
      userId,
      userEmail,
      fileURL
    )
    generalErrorReporterInitialised = true
    return Promise.resolve(generalErrorReporterInstance)
  }
}
