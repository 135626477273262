// IMPORTANT NOTE: Please don't import other selectors from this file.
// Use secondOrder and *ThirdOrder for your selector if it has other
// dependencies.

import { sortBy, groupBy } from 'lodash'
import { createSelector } from 'reselect'

import { fullFileStateSelector } from './fullFileFirstOrder'

export const allTagsSelector = createSelector(fullFileStateSelector, ({ tags }) => tags ?? [])

const selectId = (_state, id) => id
export const sortedTagsSelector = createSelector(allTagsSelector, (tags) =>
  sortBy(tags, ['title', 'id'])
)

export const singleTagSelector = createSelector(allTagsSelector, selectId, (tags, propId) =>
  tags.find((tag) => tag.id == propId)
)

export const tagsByCategorySelector = createSelector(allTagsSelector, (tags) => {
  const grouped = groupBy(tags, 'categoryId')
  if (grouped['undefined'] !== undefined) {
    grouped['null'] = grouped['undefined'].concat(grouped['null'] || [])
    delete grouped['undefined']
  }
  return grouped
})

export const stringifiedTagsByIdSelector = createSelector(allTagsSelector, (tags) => {
  return tags.reduce((acc, nextTag) => {
    return {
      ...acc,
      [nextTag.id]: JSON.stringify(nextTag).toLowerCase(),
    }
  }, {})
})
