import { saveBackup } from 'wired-up-firebase'
import { ActionTypes } from 'pltr'

import { selectors } from 'wired-up-pltr'

import { getErrorReporterInstance } from '../error-reporter-instance'

const { rawFileStateSelector, fileURLSelector, fileLoadedSelector, userIdSelector } = selectors

const { FILE_SAVED, FILE_LOADED } = ActionTypes
const BLACKLIST = [FILE_SAVED, FILE_LOADED]

let backupTimeout = null
let resetCount = 0
const MAX_RESETS = 200

const saver = (store) => (next) => (action) => {
  const result = next(action)

  const state = rawFileStateSelector(store.getState())
  const userId = userIdSelector(store.getState())
  const fileId = selectors.fileIdSelector(store.getState())
  function forceBackup() {
    saveBackup(userId, fileId, state).catch((error) => {
      getErrorReporterInstance().then((errorReporter) => {
        errorReporter.error('Error saving backup: ', error)
      })
    })
    resetCount = 0
    backupTimeout = null
  }

  // Support redux-thunk and friends where non-objects are dispatched.
  if (!action.type || BLACKLIST.includes(action.type)) {
    return result
  } else {
    const fileURL = fileURLSelector(store.getState())
    const fileLoaded = fileLoadedSelector(store.getState())
    if (fileURL && fileLoaded) {
      if (backupTimeout) {
        clearTimeout(backupTimeout)
        ++resetCount
      }
      if (resetCount >= MAX_RESETS) {
        forceBackup()
      } else {
        backupTimeout = setTimeout(forceBackup, 60000)
      }
    }

    return result
  }
}

export default saver
