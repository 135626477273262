// IMPORTANT NOTE: Please don't import other selectors from this file.
// Use secondOrder and *ThirdOrder for your selector if it has other
// dependencies.

import { createSelector } from 'reselect'

import { fullSystemStateSelector } from './fullFileFirstOrder'

const clientSelector = createSelector(fullSystemStateSelector, ({ client }) => {
  return client ?? {}
})
export const userIdSelector = createSelector(clientSelector, ({ userId }) => userId)
export const clientIdSelector = createSelector(clientSelector, ({ clientId }) => clientId)
export const emailAddressSelector = createSelector(
  clientSelector,
  ({ emailAddress }) => emailAddress
)
export const hasOnboardedSelector = createSelector(
  clientSelector,
  ({ hasOnboarded }) => hasOnboarded
)
export const isLoggedInSelector = createSelector(clientSelector, (client) => !!client.userId)
export const isOnWebSelector = createSelector(clientSelector, ({ isOnWeb }) => !!isOnWeb)
export const currentAppStateSelector = createSelector(
  clientSelector,
  ({ currentAppState }) => currentAppState
)
export const currentAppStateIsDashboardSelector = createSelector(
  currentAppStateSelector,
  (currentAppState) => currentAppState === 'dashboard'
)
const dataClientIdsSelector = createSelector(clientSelector, ({ dataClientIds }) => {
  return dataClientIds ?? {}
})
const pathSelector = (_state, path) => {
  return path
}
export const clientIdForPathSelector = createSelector(
  dataClientIdsSelector,
  pathSelector,
  (clientIds, path) => {
    return clientIds[path] ?? null
  }
)
