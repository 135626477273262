import { cloneDeep, omit } from 'lodash'

import {
  ADD_BOOK,
  ADD_BOOK_FROM_PLTR,
  ADD_BOOK_FROM_TEMPLATE,
  DELETE_BOOK,
  DUPLICATE_BOOK,
  EDIT_HIERARCHY_LEVEL,
  LOAD_HIERARCHY,
  SET_HIERARCHY_LEVELS,
  UNDO,
  REDO,
  UNDO_N_TIMES,
  REDO_N_TIMES,
} from '../constants/ActionTypes'
import { newFileHierarchies } from '../store/newFileState'
import { FILE_LOADED, NEW_FILE } from '../constants/ActionTypes'
import { hierarchyLevel } from '../store/initialState'

const hierarchy = (_dataRepairers) => (state, action) => {
  switch (action.type) {
    case SET_HIERARCHY_LEVELS: {
      if (action.hierarchyLevels.length === 0 || action.hierarchyLevels.length > 3) return state

      const oldHierarchies = state[action.timeline]

      const newHierarchies = action.hierarchyLevels.reduce(
        (acc, next, index) => ({
          ...acc,
          [index]: {
            ...next,
            level: index,
          },
        }),
        {}
      )

      const finalHierarchies =
        Object.keys(oldHierarchies).length === 1 &&
        Object.keys(newHierarchies).length === 2 &&
        newHierarchies['1'].name === newHierarchies['0'].name
          ? {
              0: newHierarchies['0'],
              1: {
                ...newHierarchies['1'],
                name: 'Scene',
              },
            }
          : newHierarchies

      return {
        ...state,
        [action.timeline]: finalHierarchies,
      }
    }

    case EDIT_HIERARCHY_LEVEL:
      return {
        ...state,
        [action.timeline]: {
          ...state[action.timeline],
          [action.hierarchyLevel.level]: {
            ...state[action.timeline][action.hierarchyLevel.level],
            ...action.hierarchyLevel,
          },
        },
      }

    case DELETE_BOOK: {
      return omit(state, action.id.toString())
    }

    case ADD_BOOK_FROM_PLTR: {
      if (typeof action.newBookId !== 'number' || state[action.newBookId]) {
        return state
      } else {
        return {
          ...state,
          [action.newBookId]: action.hierarchyLevels || { 0: hierarchyLevel() },
        }
      }
    }

    case DUPLICATE_BOOK: {
      const duplicatedHierarchy = cloneDeep(state[action.id])

      return {
        ...state,
        [action.newBookId]: duplicatedHierarchy,
      }
    }
    case ADD_BOOK_FROM_TEMPLATE:
    case ADD_BOOK: {
      return {
        ...state,
        [action.newBookId]: (action.templateData?.hierarchyLevels &&
          action.templateData?.hierarchyLevels['1']) || { 0: hierarchyLevel() },
      }
    }

    case UNDO_N_TIMES:
    case REDO_N_TIMES:
    case UNDO:
    case REDO: {
      if (action?.state?.hierarchyLevels && typeof action.state.hierarchyLevels === 'object') {
        return action.state.hierarchyLevels
      } else {
        return state
      }
    }

    case FILE_LOADED:
      return action.data.hierarchyLevels || newFileHierarchies()

    case NEW_FILE:
      return newFileHierarchies()

    case LOAD_HIERARCHY:
      return action.hierarchy

    default:
      return state || newFileHierarchies()
  }
}

export default hierarchy
