export default [
  'aliceblue',
  'antiquewhite',
  'aqua',
  'aquamarine',
  'azure',
  'beige',
  'bisque',
  'black',
  'blanchedalmond',
  'blue',
  'blueviolet',
  'brown',
  'burlywood',
  'cadetblue',
  'chartreuse',
  'chocolate',
  'coral',
  'cornflowerblue',
  'cornsilk',
  'crimson',
  'cyan',
  'darkblue',
  'darkcyan',
  'darkgoldenrod',
  'darkgray',
  'darkgreen',
  'darkkhaki',
  'darkmagenta',
  'darkolivegreen',
  'darkorange',
  'darkorchid',
  'darkred',
  'darksalmon',
  'darkseagreen',
  'darkslateblue',
  'darkslategray',
  'darkturquoise',
  'darkviolet',
  'deeppink',
  'deepskyblue',
  'dimgray',
  'dodgerblue',
  'firebrick',
  'floralwhite',
  'forestgreen',
  'fuchsia',
  'gainsboro',
  'ghostwhite',
  'gold',
  'goldenrod',
  'gray',
  'green',
  'greenyellow',
  'honeydew',
  'hotpink',
  'indianred',
  'indigo',
  'ivory',
  'khaki',
  'lavender',
  'lavenderblush',
  'lawngreen',
  'lemonchiffon',
  'lightblue',
  'lightcoral',
  'lightcyan',
  'lightgoldenrodyellow',
  'lightgray',
  'lightgreen',
  'lightpink',
  'lightsalmon',
  'lightseagreen',
  'lightskyblue',
  'lightslategray',
  'lightsteelblue',
  'lightyellow',
  'lime',
  'limegreen',
  'linen',
  'magenta',
  'maroon',
  'mediumaquamarine',
  'mediumblue',
  'mediumorchid',
  'mediumpurple',
  'mediumseagreen',
  'mediumslateblue',
  'mediumspringgreen',
  'mediumturquoise',
  'mediumvioletred',
  'midnightblue',
  'mintcream',
  'mistyrose',
  'moccasin',
  'navajowhite',
  'navy',
  'oldlace',
  'olive',
  'olivedrab',
  'orange',
  'orangered',
  'orchid',
  'palegoldenrod',
  'palegreen',
  'paleturquoise',
  'palevioletred',
  'papayawhip',
  'peachpuff',
  'peru',
  'pink',
  'plum',
  'powderblue',
  'purple',
  'rebeccapurple',
  'red',
  'rosybrown',
  'royalblue',
  'saddlebrown',
  'salmon',
  'sandybrown',
  'seagreen',
  'seashell',
  'sienna',
  'silver',
  'skyblue',
  'slateblue',
  'slategray',
  'snow',
  'springgreen',
  'steelblue',
  'tan',
  'teal',
  'thistle',
  'tomato',
  'turquoise',
  'violet',
  'wheat',
  'white',
  'whitesmoke',
  'yellow',
  'yellowgreen',
]

export const reds = [
  'MistyRose',
  'LightPink',
  'Pink',
  'Hotpink',
  'DeepPink',
  'MediumVioletRed',
  'PaleVioletRed',
  'Red',
  'Crimson',
  'Maroon',
  'DarkRed',
  'Brown',
  'Firebrick',
  'IndianRed',
  'LightCoral',
  'Salmon',
  'Tomato',
  'Coral',
  'DarkSalmon',
  'LightSalmon',
  'PeachPuff',
]

export const oranges = [
  'OrangeRed',
  'DarkOrange',
  'Orange',
  'DarkGoldenrod',
  'Goldenrod',
  'Khaki',
  'PaleGoldenrod',
  'LightGoldenrodYellow',
  'SandyBrown',
  'NavajoWhite',
  'Moccasin',
  'LemonChiffon',
  'LightYellow',
  'Yellow',
  'Gold',
]

export const greens = [
  'GreenYellow',
  'Chartreuse',
  'LawnGreen',
  'Lime',
  'LimeGreen',
  'MediumSeaGreen',
  'SeaGreen',
  'DarkGreen',
  'Green',
  'ForestGreen',
  'OliveDrab',
  'DarkOliveGreen',
  'Olive',
  'YellowGreen',
  'LightGreen',
  'PaleGreen',
  'SpringGreen',
  'MediumSpringGreen',
  'MintCream',
  'Aquamarine',
  'MediumAquaMarine',
  'DarkSeaGreen',
  'Honeydew',
  'DarkCyan',
  'Teal',
  'LightSeaGreen',
]

export const blues = [
  'DarkCyan',
  'DarkTurquoise',
  'MediumTurquoise',
  'Turquoise',
  'Cyan',
  'Aqua',
  'LightCyan',
  'Azure',
  'AliceBlue',
  'SkyBlue',
  'LightSkyBlue',
  'DeepSkyBlue',
  'DodgerBlue',
  'Blue',
  'MediumBlue',
  'DarkBlue',
  'Navy',
  'MidnightBlue',
  'RoyalBlue',
  'CornflowerBlue',
  'SteelBlue',
  'DarkSlateGray',
  'CadetBlue',
  'LightBlue',
  'PaleTurquoise',
  'PowderBlue',
  'LightSteelBlue',
  'Lavender',
  'MediumSlateBlue',
  'SlateBlue',
]

export const purples = [
  'BlueViolet',
  'DarkViolet',
  'DarkOrchid',
  'MediumOrchid',
  'Orchid',
  'Violet',
  'Fuchsia',
  'Magenta',
  'DarkMagenta',
  'Purple',
  'RebeccaPurple',
  'Indigo',
  'MediumPurple',
  'Plum',
  'Thistle',
]

export const grays = [
  'Gainsboro',
  'LightGray',
  'Silver',
  'DarkGray',
  'Gray',
  'DimGray',
  'LightSlateGray',
  'SlateGray',
  'Black',
]

export const whites = [
  'GhostWhite',
  'AntiqueWhite',
  'Beige',
  'Bisque',
  'BlanchedAlmond',
  'Ivory',
  'Linen',
  'OldLace',
  'PapayaWhip',
  'Seashell',
  'Snow',
  'LavenderBlush',
  'White',
  'WhiteSmoke',
]

export const browns = [
  'Brown',
  'Chocolate',
  'Peru',
  'RosyBrown',
  'SaddleBrown',
  'DarkGoldenrod',
  'Wheat',
]

export const defaults = [
  '#6cace4',
  '#78be20',
  '#e5554f',
  '#ff7f32',
  '#ffc72c',
  '#0b1117',
  '#f1f5f8',
]

export const allcolors = [
  ...reds,
  ...oranges,
  ...greens,
  ...blues,
  ...purples,
  ...grays,
  ...whites,
  ...browns,
]
