import axios from 'axios'
import semverGt from 'semver/functions/gt'
import { sortBy } from 'lodash'
import { getErrorReporterInstance } from './error-reporter-instance'

let env = 'prod'
if (process.env.NEXT_PUBLIC_NODE_ENV === 'development') env = 'staging'
const baseURL = `https://raw.githubusercontent.com/Plotinator/plottr_templates/${env}`
const manifestURL = `${baseURL}/v2/manifest.json`

const TEMPLATE_PREFIX = 'templates__'
const TEMPLATE_MANIFEST_KEY = 'template_manifest'

const safeParse = (string) => {
  try {
    return JSON.parse(string)
  } catch (error) {
    return null
  }
}

const localStorageValue = (key) => {
  const valueString = window.localStorage.getItem(key)
  if (!valueString) return null
  return safeParse(valueString)
}

export const manifest = () => {
  return localStorageValue(TEMPLATE_MANIFEST_KEY)
}

const fetchedManifestIsNewer = (fetchedManifest) => {
  const existingManifest = manifest()
  if (!existingManifest) return true

  return semverGt(fetchedManifest.version, existingManifest.version)
}

const templateKey = (templateId) => {
  return `${TEMPLATE_PREFIX}${templateId}`
}

const saveTemplate = (template) => {
  window.localStorage.setItem(templateKey(template.id), JSON.stringify(template))
}

const saveManifest = (manifest) => {
  window.localStorage.setItem(TEMPLATE_MANIFEST_KEY, JSON.stringify(manifest))
}

export const seedTemplates = (force = false) => {
  axios
    .get(manifestURL)
    .then((response) => {
      if (response.status == 200) {
        const manifest = response.data
        if (force || fetchedManifestIsNewer(manifest)) {
          saveManifest(manifest)
          const fetchedManifestEvent = new Event('manifest-changed')
          document.dispatchEvent(fetchedManifestEvent)
          return fetchTemplates(force, manifest)
        } else {
          return fetchTemplates(force, manifest)
        }
      } else {
        getErrorReporterInstance().then((errorReporter) => {
          errorReporter.error(`Non 200 response getting templates ${response}`)
        })
        return Promise.reject(`Non 200 response for fetching the manifest ${response}`)
      }
    })
    .then((templates) => {
      templates.forEach(saveTemplate)
      const templatesChangedEvent = new Event('templates-changed')
      document.dispatchEvent(templatesChangedEvent)
    })
    .catch((error) => {
      getErrorReporterInstance().then((errorReporter) => {
        errorReporter.error('Error fetching templates', error)
      })
    })
}

const fetchTemplate = (id, url) => {
  return axios.get(`${baseURL}${url}`).then((response) => {
    if (response.status == 200) {
      return response.data
    } else {
      return Promise.reject(`Non 200 code in ${response}`)
    }
  })
}

const templateById = (templateId) => {
  return localStorageValue(templateKey(templateId))
}

const templateIsNewer = (templateId, templateVersion) => {
  const existingTemplate = templateById(templateId)
  if (!existingTemplate || !existingTemplate.version || existingTemplate.id !== templateId) {
    return true
  } else {
    try {
      return semverGt(templateVersion, existingTemplate.version)
    } catch (error) {
      if (error.message.includes('Invalid Version')) {
        return true
      } else {
        throw error
      }
    }
  }
}

const fetchTemplates = (force, manifest) => {
  return Promise.all(
    manifest.templates.map((template) => {
      if (force || templateIsNewer(template.id, template.version)) {
        return fetchTemplate(template.id, template.url)
      } else {
        return templateById(template.id)
      }
    })
  )
}

const isTemplateKey = (key) => {
  return key.startsWith(TEMPLATE_PREFIX)
}

export const allTemplates = () => {
  return Object.entries(window.localStorage).reduce((acc, [key, templateString]) => {
    if (isTemplateKey(key)) {
      const template = safeParse(templateString)
      if (template) return [...acc, template]
    }
    return acc
  }, [])
}

export const listTemplates = (type) => {
  return sortBy(
    allTemplates().filter((template) => template.type === type),
    'name'
  )
}

export const startSaveAsTemplate = (type) => {
  const saveEvent = new Event('start-save-as-template', { bubbles: true, cancelable: false })
  // @ts-ignore
  saveEvent.templateType = type
  document.dispatchEvent(saveEvent)
}

export const messageToSaveNewTemplate = (payload) => {
  const saveEvent = new Event('save-template', { bubbles: true, cancelable: false })
  // @ts-ignore
  saveEvent.payload = payload
  document.dispatchEvent(saveEvent)
}

export const messageToEditTemplate = (_templateId, template) => {
  const editEvent = new Event('edit-template', { bubbles: true, cancelable: false })
  // @ts-ignore
  editEvent.template = template
  document.dispatchEvent(editEvent)
}

export const messageToDeleteTemplate = (templateId) => {
  const deleteEvent = new Event('delete-template', { bubbles: true, cancelable: false })
  // @ts-ignore
  deleteEvent.templateId = templateId
  document.dispatchEvent(deleteEvent)
}
