import { clone, range } from 'lodash'

import {
  ADD_LINES_FROM_TEMPLATE,
  ADD_BEAT,
  AUTO_SORT_BEAT,
  CLEAR_TEMPLATE_FROM_TIMELINE,
  DELETE_BEAT,
  EDIT_BEAT_TITLE,
  FILE_LOADED,
  NEW_FILE,
  REORDER_BEATS,
  REORDER_CARDS_IN_BEAT,
  RESET_TIMELINE,
  DELETE_BOOK,
  INSERT_BEAT,
  COLLAPSE_BEAT,
  EXPAND_BEAT,
  SET_HIERARCHY_LEVELS,
  LOAD_BEATS,
  ADD_BOOK_FROM_TEMPLATE,
  ADD_BOOK,
  APPEND_TOP_LEVEL_BEAT,
  UNSAFE_SET_BEATS,
  DUPLICATE_BOOK,
  REPLACE_MARKED_HITS,
  ADD_BOOK_FROM_PLTR,
  UNDO,
  REDO,
  UNDO_N_TIMES,
  REDO_N_TIMES,
  REORDER_OUTLINE_PLAN_CARDS,
} from '../constants/ActionTypes'
import { beat as defaultBeat } from '../store/initialState'
import { newFileBeats, newFileChapters } from '../store/newFileState'
import { positionReset, nextPositionInBook, moveNextToSibling } from '../helpers/beats'
import { associateWithBroadestScope } from '../helpers/lines'
import * as tree from './tree'
import { nextId } from '../helpers/beats'
import { sortByHitPosition } from './sortByHitPosition'
import { safeParseInt } from './safeParseInt'
import { replacePlainTextHit, replaceInSlateDatastructure } from './replace'

// bookId is:
// Union of:
//  - bookId: Number,
//  - "series": String literal,

const add = tree.addNode('id')
const newTree = tree.newTree('id')

const defaultBeats = add(clone(newTree), null, defaultBeat)

const INITIAL_STATE = {
  1: defaultBeats,
  series: defaultBeats,
}

const addNodeToState = (state, bookId, position, title, parentId) => {
  const node = {
    autoOutlineSort: true,
    bookId: bookId,
    fromTemplateId: null,
    id: nextId(state),
    position,
    time: 0,
    title: title,
    expanded: true,
  }
  const tree = state[bookId] || clone(newTree)
  return {
    ...state,
    [bookId]: add(tree, parentId, node),
  }
}

const beats =
  (_dataReparires) =>
  (state = INITIAL_STATE, action) => {
    const actionBookId = associateWithBroadestScope(action.bookId || action.newBookId)

    switch (action.type) {
      case ADD_BEAT: {
        // If we don't get a parent id then make this a root node
        const title = action.title || defaultBeat.title
        const parentId = action.parentId || null
        const position = nextPositionInBook(state, actionBookId, parentId)
        return addNodeToState(state, actionBookId, position, title, parentId)
      }

      case ADD_BOOK: {
        const title = 'auto'
        const parentId = null
        const position = nextPositionInBook(state, actionBookId, parentId)
        return addNodeToState(state, actionBookId, position, title, parentId)
      }

      case ADD_LINES_FROM_TEMPLATE: {
        if (action.createdNewBeats) {
          return {
            ...state,
            [actionBookId]: action.newTree,
          }
        } else {
          return state
        }
      }

      case DUPLICATE_BOOK: {
        const beats = action.newBeats
        const idMap = {}
        // this recreates the template's tree but with new ids
        const newBeats = tree.reduce('id')(
          beats,
          (newBeatTree, nextBeat, parentId) => {
            const newId = action.nextBeatId + nextBeat.id // give it a new id
            idMap[nextBeat.id] = newId
            const newParentId = idMap[parentId] || null
            const newBeat = {
              ...clone(nextBeat),
              id: newId,
              bookId: action.newBookId, // add it to the new book
            }
            return tree.addNode('id')(newBeatTree, newParentId, newBeat)
          },
          clone(newTree)
        )

        return {
          ...state,
          [action.newBookId]: newBeats,
        }
      }

      case ADD_BOOK_FROM_PLTR: {
        const beats = action.newBeats
        const idMap = {}
        const newBeats = tree.reduce('id')(
          beats,
          (newBeatTree, nextBeat, parentId) => {
            const newId = action.nextBeatId + nextBeat.id // give it a new id
            idMap[nextBeat.id] = newId
            const newParentId = idMap[parentId] || null
            const newBeat = {
              ...clone(nextBeat),
              id: newId,
              bookId: action.newBookId, // add it to the new book
            }
            return tree.addNode('id')(newBeatTree, newParentId, newBeat)
          },
          clone(newTree)
        )

        return {
          ...state,
          [action.newBookId]: newBeats,
        }
      }

      case ADD_BOOK_FROM_TEMPLATE: {
        const beats = action.templateData?.beats?.['1']
        if (beats && typeof beats === 'object') {
          const idMap = {}
          // this recreates the template's tree but with new ids
          const newBeats = tree.reduce('id')(
            beats,
            (newBeatTree, nextBeat, parentId) => {
              const newId = action.nextBeatId + nextBeat.id // give it a new id
              idMap[nextBeat.id] = newId
              const newParentId = idMap[parentId] || null
              const newBeat = {
                ...clone(nextBeat),
                id: newId,
                bookId: actionBookId, // add it to the new book
                fromTemplateId: action.templateData.id,
              }
              return tree.addNode('id')(newBeatTree, newParentId, newBeat)
            },
            clone(newTree)
          )
          return {
            ...state,
            [actionBookId]: newBeats,
          }
        } else {
          return state
        }
      }

      case SET_HIERARCHY_LEVELS: {
        const { hierarchyLevels, newBeatTreeForCurrentTimeline } = action
        if (
          hierarchyLevels.length === action.existingHierarchyLevelCount ||
          hierarchyLevels.length > 3 ||
          hierarchyLevels < 1
        ) {
          return state
        }

        return {
          ...state,
          [action.timeline]: newBeatTreeForCurrentTimeline,
        }
      }

      case EDIT_BEAT_TITLE:
        return {
          ...state,
          [actionBookId]: tree.editNode(state[actionBookId], action.id, { title: action.title }),
        }

      case DELETE_BOOK: {
        const newState = clone(state)
        delete newState[action.id]
        return newState
      }

      case DELETE_BEAT: {
        return {
          ...state,
          [actionBookId]: positionReset(tree.deleteNode(state[actionBookId], action.id)),
        }
      }

      case REORDER_BEATS:
        return {
          ...state,
          [actionBookId]: moveNextToSibling(
            state[actionBookId],
            action.beatId,
            action.beatDroppedOnto
          ),
        }

      case INSERT_BEAT: {
        if (!action.peerBeatId) {
          const newState = addNodeToState(
            state,
            actionBookId,
            -0.5,
            'auto',
            action.parentId || null
          )

          return {
            ...newState,
            [actionBookId]: positionReset(newState[actionBookId]),
          }
        }
        // If we don't get a parent id then make this a root node
        const parentId = tree.nodeParent(state[actionBookId], action.peerBeatId) || null
        const position = tree.findNode(state[actionBookId], action.peerBeatId).position + 0.5 // new same-level cards now appear BEFORE so user can see they have been added
        const firstBeatId = nextId(state)
        const node = {
          autoOutlineSort: true,
          bookId: actionBookId,
          fromTemplateId: null,
          id: firstBeatId,
          // Will be reset by `moveNextToSibling'
          position,
          time: 0,
          title: 'auto',
        }
        const newState = positionReset(add(state[actionBookId], parentId, node))
        if (action.timelineViewIsStacked) {
          const depthOfPeer = tree.depth(newState, action.peerBeatId)
          const depthOfTree = tree.maxDepth('id')(newState)
          const [_finalBeatId, finalState] = range(depthOfTree - depthOfPeer).reduce(
            (acc, _next) => {
              const [lastBeatId, currentState] = acc
              const node = {
                autoOutlineSort: true,
                bookId: actionBookId,
                fromTemplateId: null,
                id: lastBeatId + 1,
                // Will be reset by `moveNextToSibling'
                position,
                time: 0,
                title: 'auto',
              }
              const nextState = positionReset(add(currentState, lastBeatId, node))
              return [lastBeatId + 1, nextState]
            },
            [firstBeatId, newState]
          )
          return {
            ...state,
            [actionBookId]: finalState,
          }
        } else {
          return {
            ...state,
            [actionBookId]: newState,
          }
        }
      }

      case REORDER_OUTLINE_PLAN_CARDS:
      case REORDER_CARDS_IN_BEAT:
        return {
          ...state,
          [actionBookId]: tree.editNode(state[actionBookId], action.beatId, {
            autoOutlineSort: false,
          }),
        }

      case AUTO_SORT_BEAT:
        return {
          ...state,
          [actionBookId]: tree.editNode(state[actionBookId], action.id, { autoOutlineSort: true }),
        }

      case CLEAR_TEMPLATE_FROM_TIMELINE: {
        return {
          ...state,
          [actionBookId]: positionReset(
            tree.filter(
              state[actionBookId],
              ({ fromTemplateId }) => fromTemplateId !== action.templateId
            )
          ),
        }
      }

      case RESET_TIMELINE: {
        const withBeatsRemoved = {
          ...state,
          [actionBookId]: clone(newTree),
        }
        const newNode = {
          id: nextId(withBeatsRemoved),
          bookId: actionBookId,
          position: 0,
          title: 'auto',
          time: 0,
          autoOutlineSort: true,
          fromTemplateId: null,
        }
        return {
          ...withBeatsRemoved,
          [actionBookId]: add(withBeatsRemoved[actionBookId], null, newNode),
        }
      }

      case COLLAPSE_BEAT:
        return {
          ...state,
          [actionBookId]: tree.editNode(state[actionBookId], action.id, { expanded: false }),
        }

      case EXPAND_BEAT:
        return {
          ...state,
          [actionBookId]: tree.editNode(state[actionBookId], action.id, { expanded: true }),
        }

      case FILE_LOADED: {
        const {
          data: { beats },
        } = action
        let fixedBeats = beats
        if (!beats.series) {
          fixedBeats = {
            ...fixedBeats,
            series: clone(newTree),
          }
        }
        action.data.books.allIds.forEach((id) => {
          if (!beats[id]) {
            fixedBeats = {
              ...fixedBeats,
              [id]: clone(newTree),
            }
          }
        })
        return fixedBeats
      }

      case APPEND_TOP_LEVEL_BEAT: {
        const title = defaultBeat.title
        const parentId = null
        const position = nextPositionInBook(state, actionBookId)
        return addNodeToState(state, actionBookId, position, title, parentId)
      }

      case UNSAFE_SET_BEATS: {
        const actionBookId = associateWithBroadestScope(action.bookId || action.newBookId)
        return {
          ...state,
          [actionBookId]: action.beats,
        }
      }

      case NEW_FILE: {
        return {
          1: tree.newTree('id', ...newFileChapters),
          series: tree.newTree('id', ...newFileBeats),
        }
      }

      case REPLACE_MARKED_HITS: {
        const applicableHits = action.hitsMarkedForReplacement.filter((hit) => {
          // Only support replacing beat titles for now.
          return hit.path.match(/^\/beats\/(([0-9]+)|(series))\/[0-9]+\/title/)
        })
        // IMPORTANT!!!
        //
        // We sort by the hit position so that we deal with later hits
        // first.  By doing so, we don't invalidate the start position
        // of other hits when we replace those hits.
        //
        // i.e. it's fine to do multiple replacements in the same
        // field, as long as you replace the hits in reverse order,
        // i.e. the last hit first and the first hit last.
        return sortByHitPosition(applicableHits).reduce((acc, nextHit) => {
          const { path, hit } = nextHit
          const [_, _beats, rawBookId, rawBeatId, type, ...rest] = path.split('/')
          const bookId = String(safeParseInt(rawBookId))
          const beatId = String(safeParseInt(rawBeatId))
          const beat = acc[bookId].index[beatId]
          const [rawFocusStart] = rest
          const attributeName = type
          const attributeValue = beat[attributeName]
          const focusStart = safeParseInt(rawFocusStart)
          const replaceFunction = Array.isArray(attributeValue)
            ? replaceInSlateDatastructure
            : replacePlainTextHit
          return {
            ...acc,
            [bookId]: {
              ...acc[bookId],
              index: {
                ...acc[bookId].index,
                [beatId]: {
                  ...beat,
                  [attributeName]: replaceFunction(
                    attributeValue,
                    focusStart,
                    hit,
                    action.replacementText
                  ),
                },
              },
            },
          }
        }, state)
      }

      case UNDO_N_TIMES:
      case REDO_N_TIMES:
      case UNDO:
      case REDO: {
        if (action?.state?.beats && typeof action.state.beats === 'object') {
          return action.state.beats
        } else {
          return state
        }
      }

      case LOAD_BEATS:
        return action.beats

      default:
        return state
    }
  }

export default beats
