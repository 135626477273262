import { sortBy, groupBy, isObject, uniqBy } from 'lodash'

import { safeParseInt } from './safeParseInt'
import { beatName } from './beats'
import { positionReset } from './lists'

export function sortCardsInBeat(autoSort, cards, sortedLines) {
  if (autoSort) {
    // group by position within the line
    // for each position, sort those cards by the order of the lines
    const groupedCards = groupBy(cards, 'positionWithinLine')
    const sortedLineIds = sortedLines.map((l) => l.id)
    return Object.keys(groupedCards).flatMap((position) => {
      return groupedCards[position].sort(
        (a, b) => sortedLineIds.indexOf(a.lineId) - sortedLineIds.indexOf(b.lineId)
      )
    })
  } else {
    return sortBy(cards, 'positionInBeat')
  }
}

export function cardMapping(sortedBeats, sortedLines, card2Dmap, currentLine) {
  return sortedBeats.reduce((acc, beat) => {
    acc[beat.id] = sortedBeatCards(sortedLines, beat.id, card2Dmap, currentLine)
    return acc
  }, {})
}

export function cardMappingWithBeatTitle(
  allBeats,
  cards,
  allBooksAsArray,
  linesByBook,
  sortedHierarchyLevels
) {
  return allBooksAsArray
    .flatMap((book) => {
      const beats = Object.entries(allBeats[book.id].index).reduce(
        (acc, [beatId, beat], beatIndex) => {
          const beatTitle = beatName(allBeats[book.id], beat, sortedHierarchyLevels)
          if (String(book.id) == String(beat.bookId)) {
            acc[beatId] = {
              title: beat.title === 'auto' ? `${beatTitle} ${beatIndex + 1}` : beatTitle,
              cards: cards.filter((card) => String(card.beatId) === String(beatId)),
            }
          }
          return acc
        },
        {}
      )

      return {
        [book.id]: {
          title: book.title,
          beats,
          lines: linesByBook[book.id].lines,
        },
      }
    })
    .reduce((acc, item) => {
      return {
        ...acc,
        ...item,
      }
    }, {})
}

export function emptyCard(id, beat, line) {
  return {
    beatId: beat.id,
    bookId: beat.bookId,
    characters: [],
    color: null,
    description: [
      {
        0: {
          children: [{ 0: { text: beat.title } }],
          type: 'paragraph',
        },
      },
    ],
    fromTemplateId: null,
    id,
    imageId: null,
    lineId: line.id,
    places: [],
    positionInBeat: 0,
    positionWithinLine: 0,
    tags: [],
    templates: [],
    title: beat.title,
    isEmpty: true,
  }
}

function lineIsHidden(line, currentLine) {
  if (Array.isArray(currentLine)) {
    return !currentLine.includes(line.id)
  } else if (!currentLine) return false
  else return line.id !== currentLine
}

function sortedBeatCards(sortedLines, beatId, card2Dmap, currentLine) {
  return sortedLines.reduce((acc, l) => {
    if (lineIsHidden(l, currentLine)) return acc

    const cards = card2Dmap[`${l.id}-${beatId}`]
    if (cards) {
      return acc.concat(cards)
    } else {
      return acc
    }
  }, [])
}

export function truncateTitle(title, maxLength) {
  if (!title) return ''
  if (title.length < maxLength) return title
  return title.substr(0, maxLength) + '...'
}

export const richContentIsNonEmpty = (children) => {
  if (typeof children === 'undefined' || children === null) {
    return false
  }

  if (typeof children === 'string') {
    return children.length !== 0
  }

  return (
    children.length > 1 ||
    children.some((element) => {
      return (
        (element.text && element.text !== '') ||
        (Array.isArray(element.children) && richContentIsNonEmpty(element.children))
      )
    })
  )
}

export const cardFocusPath = (
  rawCardId,
  { baseAttributeName, customAttributeName, template } = {
    baseAttributeName: undefined,
    customAttributeName: undefined,
    template: undefined,
  }
) => {
  const cardId = safeParseInt(rawCardId)
  if (typeof rawCardId !== 'number' && `${cardId}` !== rawCardId) {
    return ['unknown']
  } else if (isObject(template)) {
    const { id, attributeName } = template
    if (typeof id !== 'string' || typeof attributeName !== 'string') {
      return ['unknown']
    } else {
      return ['card', cardId, 'template', id, attributeName]
    }
  } else if (typeof customAttributeName === 'string') {
    return ['card', cardId, customAttributeName]
  } else if (baseAttributeName && ['title', 'description'].indexOf(baseAttributeName) !== -1) {
    return ['card', cardId, baseAttributeName]
  } else {
    return ['unknown']
  }
}

export const outlineCardFocusPath = (rawCardId, hitType, view = 'plan') => {
  const cardId = safeParseInt(rawCardId)
  if (typeof rawCardId !== 'number' && `${cardId}` !== rawCardId) {
    return ['unknown']
  } else if (['title', 'description'].indexOf(hitType) !== -1) {
    return [view.toLowerCase(), 'card', cardId, hitType]
  } else {
    return ['unknown']
  }
}

export const isUniqueByKey = (array, key) => {
  const uniqueArray = uniqBy(array, key)
  return uniqueArray.length === array.length
}

export function moveCardToAbove(sourceId, newPosition, list, moveUp = true) {
  const newList = [...list]

  // Find the source index based on the source Id
  const sourcePosition = newList.findIndex((item) => item.id === sourceId)

  if (sourcePosition === -1 || newPosition < 0 || newPosition >= newList.length) {
    return newList
  }

  const [removed] = newList.splice(sourcePosition, 1)

  let insertIndex = newPosition
  if (moveUp) {
    insertIndex = newPosition === 0 ? 0 : newPosition - 1
  }

  newList.splice(insertIndex, 0, removed)

  return newList
}

export function moveCardToPosition(newPosition, list, newItem, moveUp) {
  const newList = [...list]
  const targetPosition = moveUp ? newPosition : newPosition + 1

  newList.splice(targetPosition, 0, newItem)
  return positionReset(newList, 'positionInBeat')
}
