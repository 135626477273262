import { isEmpty } from 'lodash'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import { rootReducer } from 'pltr'
import { actions } from 'wired-up-pltr'

import firebaseSync from './middlewares/firebase-sync'
import shadow from './middlewares/shadow'
import { logger } from './logger'

import saver from './middlewares/saver'
// For debugging finicky store transitions.
// import differ from './middlewares/differ'
import { getValueForStorageNamed } from './subscribableLocalStorage'

function seedWebStore(store) {
  const settings = getValueForStorageNamed('APP_SETTINGS')
  const userSettings = settings('user')
  store.dispatch(actions.client.setIsOnWeb())
  store.dispatch(actions.applicationState.finishLoadingALicenseType('trial'))
  store.dispatch(actions.applicationState.finishLoadingALicenseType('license'))
  if (!isEmpty(userSettings)) {
    store.dispatch(actions.settings.setDarkMode(userSettings.dark))
  }
}

function configureStore(initialState) {
  const window = global.window
  // @ts-ignore
  const composeEnhancers = (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
  const dataRepairers = {}
  const reducer = rootReducer(dataRepairers)

  const middlewares = applyMiddleware(saver, firebaseSync(logger), thunk, shadow)
  const enhancers =
    process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? middlewares : composeEnhancers(middlewares)
  const store = createStore(reducer, initialState, enhancers)
  seedWebStore(store)
  return store
}

const store = configureStore({})

export { store, configureStore }
