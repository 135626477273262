import {
  LOAD_FILE,
  LOAD_BEATS,
  LOAD_CARDS,
  LOAD_LINES,
  LOAD_PLACES,
  LOAD_TAGS,
  LOAD_CHARACTERS,
  LOAD_NOTES,
  LOAD_CUSTOM_ATTRIBUTES,
  LOAD_IMAGES,
  LOAD_SERIES,
  LOAD_BOOKS,
  LOAD_CATEGORIES,
  LOAD_HIERARCHY,
  LOAD_FEATURE_FLAGS,
} from './ActionTypes'

const LoadActions = [
  LOAD_FILE,
  LOAD_BEATS,
  LOAD_CARDS,
  LOAD_LINES,
  LOAD_PLACES,
  LOAD_TAGS,
  LOAD_CHARACTERS,
  LOAD_NOTES,
  LOAD_CUSTOM_ATTRIBUTES,
  LOAD_IMAGES,
  LOAD_SERIES,
  LOAD_BOOKS,
  LOAD_CATEGORIES,
  LOAD_HIERARCHY,
  LOAD_FEATURE_FLAGS,
]

export default LoadActions
