import { Logtail } from '@logtail/browser'
import { DateTime } from 'luxon'

import { sessionClientId } from './sessionClientId'

export const LAST_FIVE_THOUSAND_LOG_MESSAGES = []
const LAST_FIVE_THOUSAND_LOG_MESSAGES_KEY = 'LAST_FIVE_THOUSAND_LOG_MESSAGES'
const THIRTY_SECONDS_IN_MILISECONDS = 30 * 1000

const CONSOLE_LOGGER = {
  info: (...inputArgs) => {
    const args = inputArgs && Array.isArray(inputArgs) ? inputArgs : []
    console.log(args[0], ...args.slice(1), {
      clientId: sessionClientId(),
    })
  },
  warn: (...inputArgs) => {
    const args = inputArgs && Array.isArray(inputArgs) ? inputArgs : []
    console.warn(args[0], ...args.slice(1), {
      clientId: sessionClientId(),
    })
  },
  error: (...inputArgs) => {
    const args = inputArgs && Array.isArray(inputArgs) ? inputArgs : []
    console.error(args[0], ...args.slice(1), {
      clientId: sessionClientId(),
    })
  },
}

const LOGGER = process.env.NEXT_PUBLIC_LOGTAIL_SOURCE_TOKEN
  ? new Logtail(process.env.NEXT_PUBLIC_LOGTAIL_SOURCE_TOKEN)
  : CONSOLE_LOGGER

// If there were too many failures in the last ten seconds, then back
// off from logging them and use the console logger instead.
const FAILURE_WINDOW = 10000
const MAX_FAILURES_IN_WINDOW = 5

// TODO: maybe back off on too many requests as well?
const makeLogger = () => {
  // Seed the local storage log.
  try {
    if (typeof window !== 'undefined') {
      const existingLogs = JSON.parse(
        window.localStorage.getItem(LAST_FIVE_THOUSAND_LOG_MESSAGES_KEY) || '[]'
      )
      if (existingLogs.length) {
        existingLogs.forEach((log) => {
          LAST_FIVE_THOUSAND_LOG_MESSAGES.push(log)
        })
      }
    }
  } catch (error) {
    console.warn('Could not seed logs from local storage', error)
  }

  // Update local storage every thirty seconds with the latest logs.
  if (typeof window !== 'undefined') {
    setInterval(() => {
      try {
        window.localStorage.setItem(
          LAST_FIVE_THOUSAND_LOG_MESSAGES_KEY,
          JSON.stringify(LAST_FIVE_THOUSAND_LOG_MESSAGES)
        )
      } catch (error) {
        console.warn('Could not save logs to local storage', error)
      }
    }, THIRTY_SECONDS_IN_MILISECONDS)
  }

  function pushLog(...args) {
    if (LAST_FIVE_THOUSAND_LOG_MESSAGES.length >= 5000) {
      LAST_FIVE_THOUSAND_LOG_MESSAGES.shift()
    }
    LAST_FIVE_THOUSAND_LOG_MESSAGES.push({ time: new Date().toISOString(), log: args })
  }

  let failureWindow = []

  const cleanWindow = () => {
    const tenSecondsAgo = DateTime.now().minus({ seconds: FAILURE_WINDOW }).toSeconds()
    failureWindow = failureWindow.filter(({ timeStamp }) => timeStamp < tenSecondsAgo)
  }

  const fail = (level, args) => {
    failureWindow.push({ timeStamp: DateTime.now().toSeconds(), args, level })
  }

  const failedTooMuchRecently = () => {
    return failureWindow.length > MAX_FAILURES_IN_WINDOW
  }

  const tryToLog =
    (level) =>
    (...args) => {
      cleanWindow()
      if (failedTooMuchRecently()) {
        console.error(
          `We tried and failed to log ${failureWindow.length} times in the last ${
            FAILURE_WINDOW / 1000
          } seconds.  Falling back to the console logger`,
          ...(args || [])
        )
      } else {
        try {
          pushLog(level, ...args)
          if (!args) {
            LOGGER.warn('Call to logger without arguments!?', {
              clientId: sessionClientId(),
            })
          } else {
            LOGGER[level](args[0], ...(args.slice(1) || []), {
              clientId: sessionClientId(),
            })
          }
        } catch (error) {
          fail(level, args)
          console.error(
            'Tried to log and failed.  Falling back to console logger.  Original args follow.',
            ...(args || [])
          )
        }
      }
    }

  return {
    info: tryToLog('info'),
    warn: tryToLog('warn'),
    error: tryToLog('error'),
  }
}

export const logger = makeLogger()
