import { identity } from 'lodash'

import {
  ADD_NOTE,
  EDIT_NOTE,
  EDIT_NOTE_TEMPLATE_ATTRIBUTE,
  DELETE_NOTE,
  ATTACH_CHARACTER_TO_NOTE,
  ATTACH_PLACE_TO_NOTE,
  ATTACH_TAG_TO_NOTE,
  REMOVE_CHARACTER_FROM_NOTE,
  REMOVE_PLACE_FROM_NOTE,
  REMOVE_TAG_FROM_NOTE,
  REMOVE_BOOK_FROM_NOTE,
  ATTACH_BOOK_TO_NOTE,
  LOAD_NOTES,
  LOAD_NOTE,
  BATCH_LOAD_NOTE,
  REMOVE_NOTE,
  DUPLICATE_NOTE,
  EDIT_NOTE_TITLE,
  EDIT_NOTE_CONTENT,
  EDIT_NOTE_CUSTOM_ATTRIBUTE,
  REORDER_NOTE_MANUALLY,
} from '../constants/ActionTypes'
import { note } from '../store/initialState'
import selectors from '../selectors'

const { visibleSortedNotesByCategorySelector } = selectors(identity)

export function addNote() {
  return { type: ADD_NOTE, title: note.title, content: note.content }
}

export function addNoteWithValues(title, content) {
  return { type: ADD_NOTE, title, content }
}

export function addNoteWithContent(noteContent = note) {
  return { type: ADD_NOTE, ...noteContent }
}

export function editNote(id, attributes) {
  return { type: EDIT_NOTE, id, attributes }
}

export function editNoteTemplateAttribute(id, templateId, name, value, selection) {
  return {
    type: EDIT_NOTE_TEMPLATE_ATTRIBUTE,
    id,
    templateId,
    name,
    value,
    selection,
  }
}

export function deleteNote(id) {
  return { type: DELETE_NOTE, id }
}

export function addCharacter(id, characterId) {
  return { type: ATTACH_CHARACTER_TO_NOTE, id, characterId }
}

export function addPlace(id, placeId) {
  return { type: ATTACH_PLACE_TO_NOTE, id, placeId }
}

export function addTag(id, tagId) {
  return { type: ATTACH_TAG_TO_NOTE, id, tagId }
}

export function addBook(id, bookId) {
  return { type: ATTACH_BOOK_TO_NOTE, id, bookId }
}

export function removeCharacter(id, characterId) {
  return { type: REMOVE_CHARACTER_FROM_NOTE, id, characterId }
}

export function removePlace(id, placeId) {
  return { type: REMOVE_PLACE_FROM_NOTE, id, placeId }
}

export function removeTag(id, tagId) {
  return { type: REMOVE_TAG_FROM_NOTE, id, tagId }
}

export function removeBook(id, bookId) {
  return { type: REMOVE_BOOK_FROM_NOTE, id, bookId }
}

// NOTE: "load", "loadSingle", "batchLoad" and "removeSingle" are for
// external sync and not for general use.
export function load(patching, notes) {
  return { type: LOAD_NOTES, patching, notes }
}

// NOTE: "load", "loadSingle", "batchLoad" and "removeSingle" are for
// external sync and not for general use.
export function loadSingle(patching, note) {
  return { type: LOAD_NOTE, patching, note }
}

// NOTE: "load", "loadSingle", "batchLoad" and "removeSingle" are for
// external sync and not for general use.
export function batchLoad(patching, notes) {
  return { type: BATCH_LOAD_NOTE, patching, notes }
}

// NOTE: "load", "loadSingle", "batchLoad" and "removeSingle" are for
// external sync and not for general use.
export function removeSingle(patching, note) {
  return { type: REMOVE_NOTE, patching, note }
}

export function duplicateNote(id) {
  return { type: DUPLICATE_NOTE, id, lastEdited: new Date().getTime() }
}

export const reorderNotes =
  (noteId, oldPosition, newPosition, newCategoryId, direction) => (dispatch, getState) => {
    const visibleNotesByCategory = visibleSortedNotesByCategorySelector(getState())
    dispatch({
      type: REORDER_NOTE_MANUALLY,
      id: noteId,
      oldPosition,
      newPosition,
      newCategoryId,
      direction,
      notesByCategory: visibleNotesByCategory,
    })
  }

export function editNoteTitle(id, newTitle, selection) {
  return { type: EDIT_NOTE_TITLE, id, newTitle, selection }
}

export function editNoteContent(id, newContent, selection) {
  return { type: EDIT_NOTE_CONTENT, id, newContent, selection }
}

export function editNoteCustomAttribute(id, name, newValue, selection) {
  return { type: EDIT_NOTE_CUSTOM_ATTRIBUTE, id, name, newValue, selection }
}
