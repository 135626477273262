// IMPORTANT NOTE: Please don't import other selectors from this file.
// Use secondOrder and *ThirdOrder for your selector if it has other
// dependencies.

import { createSelector } from 'reselect'

import { fullSystemStateSelector } from './fullFileFirstOrder'

const testingAndDiagnosisSelector = createSelector(
  fullSystemStateSelector,
  ({ testingAndDiagnosis }) => {
    return testingAndDiagnosis ?? {}
  }
)

export const testingAndDiagnosisEnabledSelector = createSelector(
  testingAndDiagnosisSelector,
  ({ testingAndDiagnosisEnabled }) => {
    return testingAndDiagnosisEnabled
  }
)
