import {
  SET_USER_ID,
  SET_CLIENT_ID,
  SET_EMAIL_ADDRESS,
  SET_HAS_ONBOARDED,
  SET_IS_ON_WEB,
  SET_CURRENT_APP_STATE,
  RECORD_DATA_CLIENT_ID,
  RECORD_DATA_CLIENT_IDS,
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  userId: null,
  clientId: null,
  emailAddress: null,
  hasOnboarded: null,
  isOnWeb: null,
  currentAppState: null,
  // dataClientIds is: {
  //   [<path-to-data-in-store>: <last-client-id-that-wrote-it>]*
  // }
  dataClientIds: {},
}

const clientReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER_ID:
      return {
        ...state,
        userId: action.userId,
      }
    case SET_CLIENT_ID:
      return {
        ...state,
        clientId: action.clientId,
      }
    case SET_EMAIL_ADDRESS:
      return {
        ...state,
        emailAddress: action.emailAddress,
      }
    case SET_HAS_ONBOARDED: {
      return {
        ...state,
        hasOnboarded: action.hasOnboarded,
      }
    }
    case SET_IS_ON_WEB: {
      return {
        ...state,
        isOnWeb: true,
      }
    }
    case SET_CURRENT_APP_STATE: {
      return {
        ...state,
        currentAppState: action.appState,
      }
    }
    case RECORD_DATA_CLIENT_ID: {
      return {
        ...state,
        dataClientIds: {
          ...state.dataClientIds,
          [action.path]: action.clientId,
        },
      }
    }
    case RECORD_DATA_CLIENT_IDS: {
      return {
        ...state,
        dataClientIds: action.paths.reduce((existingPaths, nextPath) => {
          return {
            ...existingPaths,
            [nextPath]: action.clientId,
          }
        }, state.dataClientIds),
      }
    }
    default:
      return state
  }
}

export default clientReducer
