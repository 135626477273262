const settings = {
  showTheTour: false,
  backup: true,
  allowPrerelease: false,
  forceDevTools: false,
  trialMode: false,
  canGetUpdates: false,
  isInGracePeriod: false,
  gracePeriodEnd: 0,
  canEdit: true,
  canExport: true,
  locale: 'en',
  user: {
    autoDownloadUpdate: false,
    autoSave: true,
    backupDays: 30,
    backupLocation: 'default',
    dark: 'system',
    numberOfBackups: 30,
    openDashboardFirst: true,
    backupType: 'never-delete',
  },
}

export default settings
